<!--
 * @Auther: 罗耀祖
 * @Date: 2021-07-14 20:14:53
 * @LastEditors: 罗耀祖
 * @LastEditTime: 2021-07-15 21:23:19
 * @name: 
 * @Description: 
 * @FilePath: \datav\src\views\Index.vue
 * @Author: DESKTOP-1QU67QM
-->
<template>
  <dv-full-screen-container class="background">
    <div class="box">
      <div class="logo-box">
        <img class="logo" src="@/assets/image/logo.png" />
      </div>
      <div class="menu-box">
        <img class="menu" src="@/assets/image/menu.png" />
        <font>箱包产业数据平台</font>
      </div>
      <div class="right-bar">
        <div class="time-box">
          <font class="time">
            {{ time }}
          </font>
          <font class="date">
            {{ date }}
          </font>
        </div>
        <div class="wealth-box">
          <img src="@/assets/image/sun.png" class="wealth-icon" />
          <div class="wealth">
            <font class="wealth-text">天气晴</font>
            <font class="wealth-value">13~27℃</font>
          </div>
        </div>
        <div class="bar-line">
          <div class="bar-line-item" v-for="item of 4" :key="item"></div>
        </div>
      </div>
      <div class="left-box">
        <div class="left-box-top">
          <div class="data-bac-top"></div>
          <div class="data-bac-bottom"></div>
          <div class="supplier">
            <div class="item" v-for="item of supplier" :key="item.label">
              <font class="label">{{ item.label }}</font>
              <font class="value">{{ item.value }}</font>
            </div>
          </div>
          <div class="content">
            <div class="item">
              <div class="titleBar">
                <div class="titleBar-bac"></div>
                <div class="titlebox">
                  <img src="@/assets/image/menu-icon.png" class="menu-icon" />
                  <font class="title">企业分类</font>
                </div>
              </div>
              <div ref="enterpriseType"></div>
            </div>
            <div class="item">
              <div class="titleBar">
                <div class="titleBar-bac"></div>
                <div class="titlebox">
                  <img src="@/assets/image/menu-icon.png" class="menu-icon" />
                  <font class="title">企业数</font>
                </div>
              </div>
              <div ref="enterpriseNum"></div>
            </div>
          </div>
        </div>
        <div class="left-box-bottom">
          <div class="data-bac-top"></div>
          <div class="data-bac-bottom"></div>
          <div class="titleBar">
            <div class="titleBar-bac"></div>
            <div class="titlebox">
              <img src="@/assets/image/menu-icon.png" class="menu-icon" />
              <font class="title">产品分类数据</font>
            </div>
          </div>
          <div class="checkbox">
            <div
              class="item"
              :class="{ actived: item.id === productTypeActive }"
              v-for="item of productType"
              :key="item.id"
              @click="productTypeActive = item.id"
            >
              {{ item.name }}
            </div>
          </div>
          <div ref="productType"></div>
        </div>
      </div>
      <div class="center-box">
        <div class="center-box-top">
          <div class="sum-box">
            <font class="title">年度销售额</font>
            <div class="content">
              <div class="num">
                <div
                  class="item"
                  v-for="(item, index) of String(sum).split('')"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
              <font>万元</font>
            </div>
          </div>
          <div class="main">
            <dv-flyline-chart-enhanced class="China" :config="config" />
            <div class="foreign-box">
              <div class="item" v-for="item of foreign" :key="item.name">
                <font class="name">{{ item.name }}</font>
                <font class="num">{{ item.num }}</font>
              </div>
            </div>
            <div class="tabs">
              <div
                class="item"
                :class="{ actived: tabsIndex === 0 }"
                @click="tabsIndex = 0"
              >
                <font>订单流向</font>
              </div>
              <div
                class="item"
                :class="{ actived: tabsIndex === 1 }"
                @click="tabsIndex = 1"
              >
                <font>厂商分布</font>
              </div>
            </div>
            <div class="tabs2">
              <div
                class="item"
                :class="{ actived: tabsIndex2 === 0 }"
                @click="tabsIndex2 = 0"
              >
                <font>中国</font>
              </div>
              <div
                class="item"
                :class="{ actived: tabsIndex2 === 1 }"
                @click="tabsIndex2 = 1"
              >
                <font>全球</font>
              </div>
            </div>
            <div class="count-box">
              <font>数量</font>
              <div class="count-line">
                <div class="count-style"></div>
                <font>>0</font>
              </div>
              <div class="count-line">
                <div class="count-style"></div>
                <font>>100</font>
              </div>
              <div class="count-line">
                <div class="count-style"></div>
                <font>>500</font>
              </div>
              <div class="count-line">
                <div class="count-style"></div>
                <font>>1000</font>
              </div>
              <div class="count-line">
                <div class="count-style"></div>
                <font>>10000</font>
              </div>
            </div>
          </div>
        </div>
        <div class="center-box-bottom">
          <div class="data-bac-left-top"></div>
          <div class="data-bac-left-bottom"></div>
          <div class="data-bac-center-top"></div>
          <div class="data-bac-center-bottom"></div>
          <div class="data-bac-right-top"></div>
          <div class="data-bac-right-bottom"></div>
          <div class="titleBar">
            <div class="titleBar-bac"></div>
            <div class="titlebox">
              <img src="@/assets/image/menu-icon.png" class="menu-icon" />
              <font class="title">订单统计</font>
            </div>
          </div>
          <div ref="orderStatistics"></div>
        </div>
      </div>
      <div class="right-box">
        <div class="right-box-top">
          <div class="data-bac-top"></div>
          <div class="data-bac-bottom"></div>
          <div class="titleBar">
            <div class="titleBar-bac"></div>
            <div class="titlebox">
              <img src="@/assets/image/menu-icon.png" class="menu-icon" />
              <font class="title">十大品牌</font>
            </div>
          </div>
          <div class="brand">
            <div class="item" v-for="(item, index) of brands" :key="index">
              <font v-if="item.EnglishName">{{ item.EnglishName }}</font>
              <font v-if="item.ChineseName">{{ item.ChineseName }}</font>
            </div>
          </div>
        </div>
        <div class="right-box-center">
          <div class="data-bac-top"></div>
          <div class="data-bac-bottom"></div>
          <div class="titleBar">
            <div class="titleBar-bac"></div>
            <div class="titlebox">
              <img src="@/assets/image/menu-icon.png" class="menu-icon" />
              <font class="title">月销量排行</font>
            </div>
          </div>
          <div class="ranking">
            <div class="item" v-for="(item, index) of ranking" :key="index">
              <div class="circle">
                <font>{{ index + 1 }}</font>
              </div>
              <div class="name">
                <font>{{ item.name }}</font>
              </div>
              <div class="price">
                <font>{{ item.price }}元</font>
              </div>
            </div>
          </div>
        </div>
        <div class="right-box-bottom">
          <div class="data-bac-top"></div>
          <div class="data-bac-bottom"></div>
          <div class="titleBar">
            <div class="titleBar-bac"></div>
            <div class="titlebox">
              <img src="@/assets/image/menu-icon.png" class="menu-icon" />
              <font class="title">行业产值</font>
            </div>
          </div>
          <div ref="output"></div>
        </div>
      </div>
    </div>
  </dv-full-screen-container>
</template>
<script>
import * as echarts from "echarts";

export default {
  name: "index",
  data() {
    return {
      timeInterval: "", //定时器
      enterpriseTypeInterval: "", //饼图循环定时器
      date: "", //日期
      time: "", //时间
      supplier: [
        {
          label: "成品厂商",
          value: "1975",
        },
        {
          label: "原材料厂商",
          value: "2335",
        },
        {
          label: "其他供应商",
          value: "453",
        },
      ],
      productType: [
        {
          id: 1,
          name: "拉杆箱",
        },
        {
          id: 2,
          name: "背包",
        },
        {
          id: 3,
          name: "手提箱",
        },
        {
          id: 4,
          name: "特殊箱包",
        },
        {
          id: 5,
          name: "钱包卡包",
        },
      ],
      productTypeActive: 1,
      brands: [
        {
          EnglishName: "Samsonite",
          ChineseName: "新秀丽",
        },
        {
          EnglishName: "Louis Vuitton",
          ChineseName: "路易·威登",
        },
        {
          EnglishName: "Diplomat ",
          ChineseName: "外交官",
        },
        {
          EnglishName: "Lancel",
          ChineseName: "兰姿",
        },
        {
          EnglishName: "Thelebre",
          ChineseName: "希伯莱",
        },
        {
          EnglishName: "VOYLUX",
          ChineseName: "",
        },
        {
          EnglishName: "President",
          ChineseName: "凌秀",
        },
        {
          EnglishName: "FEGLAR ",
          ChineseName: "菲格尔",
        },
        {
          EnglishName: "Rimowa",
          ChineseName: "",
        },
        {
          EnglishName: "",
          ChineseName: "皇冠",
        },
      ],
      ranking: [
        {
          name: "祥兴（福建）箱包集团有限公司",
          price: "764430",
        },
        {
          name: "威海市金猴集团有限责任公司",
          price: "652000",
        },
        {
          name: "浙江泰普森休闲用品有限公司",
          price: "569000",
        },
        {
          name: "泉州子燕轻工有限公司",
          price: "490000",
        },
        {
          name: "捷爱斯（青岛）轻工制品有限公司",
          price: "280000",
        },
        {
          name: "达派（中国）箱包有限公司",
          price: "259000",
        },
        {
          name: "广州市斐高箱包有限公司",
          price: "244430",
        },
        {
          name: "杭州立山皮件有限公司",
          price: "238900",
        },
        {
          name: "嘉兴新秀箱包制造有限公司",
          price: "207800",
        },
      ],
      sum: 718672,
      tabsIndex: 0,
      tabsIndex2: 1,
      foreign: [
        {
          name: "巴基斯坦",
          num: 32,
        },
        {
          name: "老挝",
          num: 32,
        },
        {
          name: "马来西亚",
          num: 32,
        },
        {
          name: "尼泊尔",
          num: 32,
        },
        {
          name: "南非",
          num: 32,
        },
        {
          name: "澳大利亚",
          num: 32,
        },
        {
          name: "新西兰",
          num: 32,
        },
        {
          name: "美国",
          num: 32,
        },
        {
          name: "奥地利",
          num: 32,
        },
        {
          name: "英国",
          num: 32,
        },
      ],
      config: {
        points: [
          {
            name: "浙江",
            coordinate: [0.85, 0.65],
            halo: {
              show: true,
              color: "#08CDF7",
            },
          },
          {
            name: "黑龙江",
            coordinate: [0.91, 0.19],
          },
          {
            name: "吉林",
            coordinate: [0.91, 0.29],
          },
          {
            name: "辽宁",
            coordinate: [0.86, 0.33],
          },
          {
            name: "北京",
            coordinate: [0.75, 0.39],
          },
          {
            name: "河北",
            coordinate: [0.73, 0.43],
          },
          {
            name: "山东",
            coordinate: [0.78, 0.47],
          },
          {
            name: "山西",
            coordinate: [0.68, 0.46],
          },
          {
            name: "宁夏",
            coordinate: [0.57, 0.46],
          },
          {
            name: "内蒙古",
            coordinate: [0.6, 0.38],
          },
          {
            name: "新疆",
            coordinate: [0.2, 0.35],
          },
          {
            name: "西藏",
            coordinate: [0.21, 0.59],
          },
          {
            name: "云南",
            coordinate: [0.48, 0.78],
          },
          {
            name: "广西",
            coordinate: [0.62, 0.79],
          },
          {
            name: "广东",
            coordinate: [0.73, 0.79],
          },
          {
            name: "巴基斯坦",
            coordinate: [0, 0.17],
          },
          {
            name: "老挝",
            coordinate: [0, 0.23],
          },
          {
            name: "马来西亚",
            coordinate: [0, 0.29],
          },
          {
            name: "尼泊尔",
            coordinate: [0, 0.35],
          },
          {
            name: "南非",
            coordinate: [0, 0.41],
          },
          {
            name: "澳大利亚",
            coordinate: [0, 0.47],
          },
          {
            name: "新西兰",
            coordinate: [0, 0.53],
          },
          {
            name: "美国",
            coordinate: [0, 0.59],
          },
          {
            name: "奥地利",
            coordinate: [0, 0.65],
          },
          {
            name: "英国",
            coordinate: [0, 0.71],
          },
        ],
        lines: [
          {
            source: "黑龙江",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "吉林",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "辽宁",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "北京",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "河北",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "山东",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "山西",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "宁夏",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "内蒙古",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "新疆",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "西藏",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "云南",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "广西",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "广东",
            target: "浙江",
            color: "#21F3BF",
          },
          {
            source: "巴基斯坦",
            target: "浙江",
            color: "#E3891B",
            orbitColor: "#E3891B",
            duration: 10000,
          },
          {
            source: "老挝",
            target: "浙江",
            color: "#E3891B",
            orbitColor: "#E3891B",
            duration: 10000,
          },
          {
            source: "马来西亚",
            target: "浙江",
            color: "#E3891B",
            orbitColor: "#E3891B",
            duration: 10000,
          },
          {
            source: "尼泊尔",
            target: "浙江",
            color: "#E3891B",
            orbitColor: "#E3891B",
            duration: 10000,
          },
          {
            source: "南非",
            target: "浙江",
            color: "#E3891B",
            orbitColor: "#E3891B",
            duration: 10000,
          },
          {
            source: "澳大利亚",
            target: "浙江",
            color: "#E3891B",
            orbitColor: "#E3891B",
            duration: 10000,
          },
          {
            source: "新西兰",
            target: "浙江",
            color: "#E3891B",
            orbitColor: "#E3891B",
            duration: 10000,
          },
          {
            source: "美国",
            target: "浙江",
            color: "#E3891B",
            orbitColor: "#E3891B",
            duration: 10000,
          },
          {
            source: "奥地利",
            target: "浙江",
            color: "#E3891B",
            orbitColor: "#E3891B",
            duration: 10000,
          },
          {
            source: "英国",
            target: "浙江",
            color: "#E3891B",
            orbitColor: "#E3891B",
            duration: 10000,
          },
        ],
        bgImgSrc: require("@/assets/image/China.png"),
      },
    };
  },
  created() {
    this.timeInterval = setInterval(() => {
      let nowTime = new Date();
      let YYYY = nowTime.getFullYear();
      let MM = String(nowTime.getMonth() + 1).padStart(2, "0");
      let DD = String(nowTime.getDate()).padStart(2, "0");
      let hh = String(nowTime.getHours()).padStart(2, "0");
      let mm = String(nowTime.getMinutes()).padStart(2, "0");
      let ss = String(nowTime.getSeconds()).padStart(2, "0");
      this.date = YYYY + "年" + MM + "月" + DD + "日";
      this.time = hh + ":" + mm + ":" + ss;
    }, 1000);
  },
  mounted() {
    setTimeout(() => {
      this.getEnterpriseType();
      this.getEnterpriseNum();
      this.getProductType();
      this.getOutput();
      this.getOrderStatistics();
    });
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
    clearInterval(this.enterpriseTypeInterval);
  },
  methods: {
    getEnterpriseType() {
      let myChart = echarts.init(this.$refs.enterpriseType);
      let option = {
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "企业分类",
            type: "pie",
            radius: ["30%", "70%"],
            data: [
              { value: 0.218, name: "软箱" },
              { value: 0.267, name: "硬箱" },
              { value: 0.235, name: "配件类" },
              { value: 0.118, name: "包类" },
              { value: 0.162, name: "其他类" },
            ],
            label: {
              show: true,
              positon: "outside",
              color: "white",
              formatter: "{b}\n{d}%",
            },
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option);
      var index = 0;

      this.enterpriseTypeInterval = setInterval(function () {
        var dataLen = option.series[0].data.length;
        // 取消之前高亮的图形
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
        });
        index = (index + 1) % dataLen;
        // 高亮当前图形
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: index,
        });
        myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: index,
        });
      }, 2000);
      myChart.on("mouseover", (e) => {
        // 停止定时器，清除之前的高亮
        clearInterval(this.enterpriseTypeInterval);
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0, //清一下高亮
        });
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: e.dataIndex, //当前鼠标选中的高亮
        });
        myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
      });
      myChart.on("mouseout", (e) => {
        // 停止定时器，清除之前的高亮
        clearInterval(this.enterpriseTypeInterval);
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0, //清一下高亮
          dataIndex: e.dataIndex,
        });
        this.enterpriseTypeInterval = setInterval(function () {
          var dataLen = option.series[0].data.length;
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
          });
          index = (index + 1) % dataLen;
          // 高亮当前图形
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: index,
          });
          myChart.dispatchAction({
            type: "showTip",
            seriesIndex: 0,
            dataIndex: index,
          });
        }, 2000);
      });
    },
    getEnterpriseNum() {
      let myChart = echarts.init(this.$refs.enterpriseNum);
      myChart.setOption({
        grid: {
          // show: true,
          top: "40",
          left: "10",
          right: "10",
          bottom: "20",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(2,76,112, .2)",
                // type: 'dashed'
              },
            },
            boundaryGap: false,
            data: ["", "1", "2", "3", "4", "5", "6"],
            axisTick: {
              inside: true,
            },
            offset: 2,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(2,76,112, .2)",
              },
            },
            splitNumber: 3,
          },
        ],
        series: [
          {
            type: "line",
            stack: "总量",
            smooth: true,
            lineStyle: {
              width: 2,
              color: "rgba(167,247,81)",
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(126,255,0,0.54)",
                },
                {
                  offset: 1,
                  color: "rgba(127,255,36,0)",
                },
              ]),
            },
            data: [160, 210, 170, 220, 240, 150, 215, 225, 300, 320, 360, 370],
          },
        ],
      });
    },
    getProductType() {
      let myChart = echarts.init(this.$refs.productType);
      // 绘制图表
      myChart.setOption({
        legend: {
          data: ["合格率", "销量", "库存"],
          bottom: "0",
          textStyle: {
            fontSize: 12,
            color: "#F1F1F3",
          },
        },
        xAxis: {
          type: "category",
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              type: "dashed",
            },
          },
          data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              type: "dashed",
            },
          },
        },
        grid: {
          left: "40",
          top: "10",
          right: "10",
          bottom: "50",
        },
        series: [
          {
            name: "合格率",
            data: [70, 68, 34, 52, 33, 44, 24, 28, 42, 41, 37, 16],
            type: "bar",
            barWidth: 3,
          },
          {
            name: "销量",
            data: [50, 48, 24, 42, 23, 34, 14, 18, 32, 31, 27, 6],
            type: "bar",
            barWidth: 3,
          },
          {
            name: "库存",
            data: [40, 38, 14, 52, 13, 44, 24, 38, 52, 11, 27, 36],
            type: "bar",
            barWidth: 3,
          },
        ],
      });
    },
    getOutput() {
      let myChart = echarts.init(this.$refs.output);
      myChart.setOption({
        grid: {
          // show: true,
          top: "20",
          left: "10",
          right: "10",
          bottom: "10",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(2,76,112, .2)",
                // type: 'dashed'
              },
            },
            boundaryGap: false,
            data: ["", "1", "2", "3", "4", "5", "6"],
            axisTick: {
              inside: true,
            },
            offset: 2,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(2,76,112, .2)",
              },
            },
            splitNumber: 3,
          },
        ],
        series: [
          {
            type: "line",
            stack: "总量",
            smooth: true,
            lineStyle: {
              width: 2,
              color: "rgba(167,247,81)",
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(126,255,0,0.54)",
                },
                {
                  offset: 1,
                  color: "rgba(127,255,36,0)",
                },
              ]),
            },
            data: [160, 210, 170, 220, 240, 150, 215, 225, 300, 320, 360, 370],
          },
        ],
      });
    },
    getOrderStatistics() {
      let myChart = echarts.init(this.$refs.orderStatistics);
      myChart.setOption({
        color: ["#80D94A", "#5FA8FF", "#D77888"],
        legend: {
          itemWidth: 20,
          right: 20,
          icon: "rect",
          textStyle: {
            fontSize: 12,
            color: "#F1F1F3",
          },
        },
        grid: {
          top: 20,
          bottom: 20,
          left: 30,
          right: 10,
        },
        tooltip: {
          trigger: "axis",
          showContent: false,
        },
        dataset: {
          source: [
            [
              "category",
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ],
            ["软箱", 56, 82, 88, 70, 53, 85, 51, 51, 55, 53, 73, 68],
            ["硬箱", 51, 51, 55, 53, 73, 68, 56, 82, 88, 70, 53, 85],
            ["包", 40, 62, 69, 36, 45, 32, 53, 73, 68, 56, 82, 30],
          ],
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            show: true,
          },
          splitLine: {
            show: true,
            interval: "0",
            lineStyle: {
              color: "rgba(2,76,112, .2)",
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          gridIndex: 0,
          type: "value",
          // onZero: true,
          axisLine: {
            show: true,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(2,76,112, .2)",
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            type: "line",
            smooth: true,
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
          },
          {
            type: "line",
            smooth: true,
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
          },
          {
            type: "line",
            smooth: true,
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "YouSheBiaoTiHei";
  src: url("../assets/font/YouSheBiaoTiHei.ttf");
}
@font-face {
  font-family: "DINCondensedBold";
  src: url("../assets/font/DINCondensedBold.ttf");
}
.data-bac {
  position: relative;
  padding: 15px;
}
.data-bac-left-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  height: 50%;
  background: url("../assets/image/data-bac.png") top left;
  background-size: 125%;
  z-index: -1;
}
.data-bac-left-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  height: 50%;
  background: url("../assets/image/data-bac.png") bottom left;
  background-size: 125%;
  z-index: -1;
}
.data-bac-center-top {
  position: absolute;
  top: 0;
  left: 40%;
  width: 20%;
  height: 50%;
  background: url("../assets/image/data-bac.png") top center;
  background-size: 250%;
  z-index: -1;
}
.data-bac-center-bottom {
  position: absolute;
  bottom: 0;
  left: 40%;
  width: 20%;
  height: 50%;
  background: url("../assets/image/data-bac.png") bottom center;
  background-size: 250%;
  z-index: -1;
}
.data-bac-right-top {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 50%;
  background: url("../assets/image/data-bac.png") top right;
  background-size: 125%;
  z-index: -1;
}
.data-bac-right-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
  height: 50%;
  background: url("../assets/image/data-bac.png") bottom right;
  background-size: 125%;
  z-index: -1;
}
.data-bac-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: url("../assets/image/data-bac.png") top left;
  background-size: 100%;
  z-index: -1;
}
.data-bac-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: url("../assets/image/data-bac.png") bottom left;
  background-size: 100%;
  z-index: -1;
}
.titleBar {
  position: relative;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .titleBar-bac {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 100%;
    background-image: linear-gradient(
      90deg,
      rgba(2, 89, 130, 0.29) 0%,
      rgba(3, 96, 140, 0) 100%
    );
  }
  .titlebox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .menu-icon {
      width: 12px;
      height: 12px;
      margin-right: 7px;
    }
    .title {
      font-size: 14px;
      color: white;
    }
  }
}
.background {
  background: url("../assets/image/background.png") no-repeat center center;
  .box {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 60px auto;
    grid-template-columns: 1fr 2fr 1fr;
    column-gap: 10px;
    .logo-box {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .logo {
        position: absolute;
        max-height: 100%;
      }
    }
    .menu-box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .menu {
        position: absolute;
        max-height: 100%;
      }
      font-family: "YouSheBiaoTiHei";
      font-size: 40px;
      color: #8af3ff;
      letter-spacing: 0;
      font {
        margin-bottom: 10px;
      }
    }
    .right-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      .time-box {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
        margin-right: 20px;
        font-family: "DINCondensedBold";
        color: white;
        height: 100%;
        .time {
          font-size: 30px;
        }
        .date {
          font-size: 16px;
        }
      }
      .wealth-box {
        height: 44px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 20px;
        .wealth-icon {
          width: 44px;
          height: 44px;
        }
        .wealth {
          height: 44px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          font-size: 14px;
          color: white;
        }
      }
      .bar-line {
        position: absolute;
        bottom: 0;
        right: 0;
        display: grid;
        width: 66%;
        grid-template-columns: repeat(3, 1fr) 2fr;
        grid-template-rows: 2px;
        gap: 8px;
        .bar-line-item {
          @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
              @if $i % 2 == 0 {
                background: rgba(0, 255, 255, 0.3);
              } @else {
                background: rgba(0, 255, 255, 0.1);
              }
            }
          }
        }
      }
    }
    .left-box {
      padding: 10px;
      padding-right: 0;
      display: grid;
      grid-template-rows: 581fr 401fr;
      grid-template-columns: 1fr;
      gap: 10px;
      .left-box-top {
        @extend .data-bac;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 55px auto;
        gap: 10px;
        .supplier {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 1fr;
          gap: 10px;
          .item {
            background: rgba(6, 53, 130, 0.2);
            border: 1px solid #00b6ff;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .label {
              font-size: 12px;
              color: white;
              letter-spacing: 0.35px;
              margin-bottom: 5px;
            }
            .value {
              font-family: "DINCondensedBold";
              font-size: 24px;
              color: #ffa63a;
            }
          }
        }
        .content {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: repeat(2, 1fr);
          .item {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 26px auto;
          }
        }
      }
      .left-box-bottom {
        @extend .data-bac;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 26px 42px auto;
        gap: 10px;
        .checkbox {
          display: grid;
          grid-template-rows: 1fr;
          grid-template-columns: repeat(5, 1fr);
          gap: 5px;
          .item {
            background: rgba(56, 187, 208, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: white;
            border-radius: 8px;
            cursor: pointer;
            &.actived {
              background: rgba(56, 187, 208, 0.53);
            }
          }
        }
      }
    }
    .center-box {
      padding: 10px 0;
      display: grid;
      grid-template-rows: 757fr 220fr;
      grid-template-columns: 1fr;
      gap: 10px;
      .center-box-top {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 12% auto;
        .sum-box {
          width: 85%;
          justify-self: center;
          background: url("../assets/image/sum-bac.png");
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: white;
          .title {
            margin-bottom: 5px;
            letter-spacing: 0.5px;
          }
          .content {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            .num {
              margin-right: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: flex-end;
              .item {
                background: #0b88af;
                border-radius: 3px;
                padding: 5px 8px;
                font-size: 28px;
                color: white;
                font-family: "DINCondensedBold";
                margin-right: 5px;
              }
            }
          }
        }
        .main {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .China {
            width: 75%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;
          }
          .foreign-box {
            position: absolute;
            top: 0;
            left: 2.5%;
            width: 10%;
            height: 100%;
            .item {
              position: absolute;
              right: 0;
              height: 5%;
              width: 100%;
              @for $i from 1 through 10 {
                &:nth-child(#{$i}) {
                  top: calc(9% + 5% * #{$i} + 1% * #{$i});
                }
              }
              display: flex;
              justify-content: space-between;
              align-items: center;
              border: 1px solid #1f6a93;
              border-radius: 4px;
              padding: 3px;
              box-sizing: border-box;
              background: rgba(0, 25, 38, 0.8);
              .name {
                font-size: 12px;
                color: white;
              }
              .num {
                font-size: 12px;
                color: #ff8405;
              }
            }
          }
          .tabs {
            position: absolute;
            top: 15px;
            width: 150px;
            height: 27px;
            border-radius: 6px;
            border: 1px solid #1f6a93;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            .item {
              flex: 1;
              height: 100%;
              background: transparent;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              color: white;
              cursor: pointer;
              &.actived {
                background: #3fcaf1;
              }
            }
          }
          .tabs2 {
            position: absolute;
            bottom: 25px;
            left: 80px;
            width: 90px;
            height: 27px;
            border-radius: 6px;
            border: 1px solid #1f6a93;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            .item {
              flex: 1;
              height: 100%;
              background: transparent;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              color: white;
              cursor: pointer;
              &.actived {
                background: #3fcaf1;
              }
            }
          }
          .count-box {
            position: absolute;
            bottom: 25px;
            right: 15px;
            padding: 5px;
            display: grid;
            grid-template-columns: 60px;
            grid-template-rows: repeat(auto, auto);
            font-size: 12px;
            color: white;
            gap: 5px;
            .count-line {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .count-style {
                box-sizing: border-box;
                width: 13px;
                height: 13px;
                border: 1px solid #41f1ff;
                margin-right: 5px;
              }
              $count_backs: (2, rgba(0, 51, 112, 0.6)),
                (3, rgba(13, 205, 255, 0.2)), (4, rgba(13, 205, 255, 0.4)),
                (5, rgba(13, 205, 255, 0.6)), (6, rgba(13, 205, 255, 0.9));
              @each $i, $color in $count_backs {
                &:nth-child(#{$i}) {
                  .count-style {
                    background: $color;
                  }
                }
              }
            }
            font {
              zoom: 0.8;
            }
          }
        }
      }
      .center-box-bottom {
        @extend .data-bac;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 26px auto;
      }
    }
    .right-box {
      padding: 10px;
      padding-left: 0;
      display: grid;
      grid-template-rows: 261fr 481fr 231fr;
      grid-template-columns: 1fr;
      gap: 10px;
      .right-box-top {
        @extend .data-bac;
        padding: 15px 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 26px auto;
        gap: 20px;
        .brand {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-template-rows: repeat(2, 1fr);
          gap: 5px;
          font-size: 12px;
          color: white;
          .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;
            white-space: nowrap;
            background: rgba(56, 187, 208, 0.1);
            border-radius: 8px;
            font {
              zoom: 0.8;
            }
          }
        }
      }
      .right-box-center {
        @extend .data-bac;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 26px auto;
        gap: 10px;
        .ranking {
          padding: 10px;
          display: grid;
          grid-template-columns: 1;
          grid-template-rows: repeat(auto, auto);
          .item {
            border-top: 1px solid #003a6d;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .circle {
              width: 24px;
              height: 24px;
              border-radius: 24px;
              background-image: linear-gradient(0deg, #438100 0%, #a2d818 100%);
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
            }
            .name {
              margin: 0 5px;
              flex: 1;
              font-size: 12px;
              color: white;
            }
            .price {
              font-size: 12px;
              color: #13c8f4;
            }
            @for $i from 1 through 3 {
              &:nth-child(#{$i}) {
                .name,
                .price {
                  color: #ff970c !important;
                }
                @if $i == 1 {
                  border-top: 0;
                }
              }
            }
          }
        }
      }
      .right-box-bottom {
        @extend .data-bac;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 26px auto;
      }
    }
  }
}
</style>


